<template>
  <div class="sell-block-parent">
    <section class="d-flex flex-wrap-reverse sell-block">
      <div class="col-12 col-md-6 sell-block-container">
        <div
          class="sell-block__info d-flex flex-column align-items-center justify-content-center text-center position-relative overflow-hidden heigth-full"
        >
          <h2 class="sell-block__info__title">
            {{ $t('main.sell_with_us.title') }}
          </h2>
          <!-- <p class="sell-block__info__description text-uppercase">
            {{ $t("main.sell_with_us.descriptions") }}
          </p> -->

          <!-- <tooltip :enterable="true" class="width-100" :content="$t('main.best_sales_descriptions')"> -->
          <!-- <p v-text-line-clamp="4" class="recommended-sellers-block__description text-uppercase">
              {{ $t('main.best_sales_descriptions') }}
            </p> -->

          <p class="sell-block__info__description">
            {{ $t('main.sell_with_us.descriptions') }}
          </p>
          <!-- </tooltip> -->
          <nuxt-link
            tag="a"
            :to="{ path: localePath('/auth/registration/seller') }"
            class="btn primary-btn shadow-none rounded-0 d-inline-flex align-items-center justify-content-center text-uppercase text-decoration-none disabled"
          >
            {{ $t('main.sell_with_us.btn_text') }}
          </nuxt-link>
        </div>
      </div>
      <figure class="col-12 col-md-6 m-0 sell-block__image">
        <img
          v-lazy-load
          data-src="/images/sell-with-us.webp"
          class="w-100"
          alt="Sell-with-us"
          height="720"
          width="700"
        />
      </figure>
    </section>
  </div>
</template>

<script>
export default {
  name: 'CardStartSelling'
}
</script>

<style scoped>
.sell-block-parent{
  background-color: #FFFFFF;
}
.heigth-full {
  height: 100%;
}
</style>
